



























import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import AuthService from "@/services/auth-service";

import config from "@/config";

const appState = getModule(AppState);

@Component
export default class Drawer extends Vue {
  @Inject() AuthService!: AuthService;

  get drawerState() {
    return appState.drawer;
  }

  links: any[] = [];

  async mounted() {
    this.links = await this.getAuthLink(config.navigation);
  }

  async getAuthLink(links: any[]) {
    const user = await this.AuthService.getUser();

    if (!user) return links.filter(link => !link.permission);

    return links.filter(
      link => !link.permission || user.Permissions.filter(ro => ro === link.permission).length > 0
    );
  }
}
