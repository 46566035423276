import Vue from "vue";

Vue.filter(
  'kebab-to-pascal',
  (value: string) => {
    if (!value || typeof value !== 'string') return value;

    return value
      .split('-')
      .map(x => x[0].toUpperCase() + x.substring(1))
      .join(' ');
  }
);

