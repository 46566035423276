









import '@/styles/index.scss';

import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import LayoutToolbar from '@/components/layout/Toolbar.vue';
import LayoutDrawer from '@/components/layout/Drawer.vue';
import LayoutMain from '@/components/layout/Main.vue';
import LayoutFooter from '@/components/layout/Footer.vue';
import { RouteConfig } from 'vue-router';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';

const appState = getModule(AppState);

@Component({
  components: {
    LayoutToolbar,
    LayoutDrawer,
    LayoutMain,
    LayoutFooter
  },
})
export default class App extends Vue {
  @Watch('$route')
  onRouteChange(route: RouteConfig) {
    appState.setTitle(route.meta.title);
  }
}
