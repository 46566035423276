import axios, { AxiosRequestConfig } from 'axios';
import AuthService from '@/services/auth-service';
import { WillHandle, CustomErrorMessageHandle } from '@/services/axios-service.d';


class AxiosService {
  authService: AuthService;

  constructor(authService?: AuthService) {
    this.authService = authService ?? new AuthService();
  }

  async get(url: string, config?: AxiosRequestConfig): Promise<any> {
    config = {
      ...config,
      headers: await this.headers(config?.headers)
    };

    const response = await axios.get(url, config);
    return response.data;
  }

  async put(
    url: string,
    payload: any,
    config?: AxiosRequestConfig & WillHandle): Promise<any> {
    config = {
      ...config,
      headers: await this.headers(config?.headers)
    };
    const response = await axios.put(url, payload, config);

    return response.data;
  }

  async delete(url: string, payload?: any, config?: AxiosRequestConfig & WillHandle): Promise<any> {
    config = {
      ...config,
      headers: await this.headers(config?.headers),
      data: payload
    };

    const response = await axios.delete(url, config);

    return response;
  }

  async post(
    url: string,
    payload: any,
    config?: AxiosRequestConfig & WillHandle & CustomErrorMessageHandle
  ): Promise<any> {
    config = {
      ...config,
      headers: await this.headers(config?.headers),
    };

    const response = await axios.post(url, payload, config);
    return response.data;
  }

  private async headers(extraHeaders?: any): Promise<any> {
    const token = await this.authService.getToken();
    const xhcdKey = (Math.random() * 1e36).toString(36);

    return {
      ...extraHeaders,
      Authorization: `Bearer ${token}`,
      Accept: 'application/hal+json',
      'X-HazcheckDetect-Key': `${xhcdKey}`,
    };
  }
}
export default AxiosService;
