import Vue from 'vue';
import Vuex from 'vuex';
import { VuexPersistence } from 'vuex-persist';

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence({ storage: localStorage });

export default new Vuex.Store({
  plugins: [vuexPersistence.plugin],
});

export function hasState(name: string): boolean {
  const state = localStorage.getItem('vuex');

  if (state == null) return false;

  const json = JSON.parse(state);

  return name in json;
}
