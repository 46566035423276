import Vue from "vue";
import plugins from "@/plugins/index";
import { servicesLoading } from "@/services/index";
import "@/filters/index";

import App from "./App.vue";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-dayjs";
import "./registerServiceWorker";

import router from "./router";
import store from "./store";

Chart.register(...registerables);

Vue.config.productionTip = false;

Promise.all([servicesLoading, router]).then(([services, router]) => {
  const vue = new Vue({
    router,
    store,
    ...plugins,
    provide: services,
    render: h => h(App)
  });

  (window as any).$$vue = vue;

  vue.$mount("#app");
});
