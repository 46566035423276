import { RouteConfig } from 'vue-router/types/router.d';
import { configLoading, Config } from '@/config';

import Home from '../views/Home.vue';
const Login = () => import(/* webpackChunkName: "login" */ `@/views/Login.vue`);
const Logout = () => import(/* webpackChunkName: "logout" */ `@/views/Logout.vue`);
const Analytics = () => import(/* webpackChunkName: "cases" */ `@/views/Analytics.vue`);

const analyticsRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'root',
    component: Home,
    meta: {
      title: 'Welcome',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: 'Logout',
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics,
    meta: {
      title: 'Analytics',
      isSecure: true
    },
  }
];

function notFoundRoute(config: Config): RouteConfig {
  return {
    path: '*',
    name: 'not-found',
    beforeEnter: (to) => {
      window.location.href = `${config.hazcheckDetectUrl}/#${to.path}`
    }
  };
}

function hazcheckDetectRoutes(config: Config): RouteConfig[] {
  return ['libraries', 'service-hooks', 'policies', 'screen-results'].map(name => ({
    path: `/${name}/*`,
    name: name,
    beforeEnter: (to) => {
      window.location.href = `${config.hazcheckDetectUrl}/#${to.path}`
    }
  }));
}

function caseManagementRoutes(config: Config): RouteConfig[] {
  return ['cases', 'email-templates', 'configuration'].map(name => ({
    path: `/${name}/*`,
    name: name,
    beforeEnter: (to) => {
      window.location.href = `${config.caseManagementUrl}/#${to.path}`
    }
  }));
}

export default configLoading
  .then((config) => [
    ...analyticsRoutes,
    ...caseManagementRoutes(config),
    ...hazcheckDetectRoutes(config),
    notFoundRoute(config)
  ]);
