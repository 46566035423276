import { configLoading } from '@/config';

import AxiosService from './axios-service';
import AuthService from './auth-service';
import AnalyticsService from './analytics-service';
import ConfigurationService from './configuration-service';

const services: any = {};

export const servicesLoading = configLoading.then((config) => {
  const authService = new AuthService();
  const axiosService = new AxiosService(authService);

  return Object.assign(services, {
    AuthService: authService,
    AnalyticsService: new AnalyticsService(axiosService, config),
    ConfigurationService: new ConfigurationService(axiosService)
  });
});

export default services;
